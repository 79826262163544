import axios from 'axios'

import { ApiResponse, Resume, Experience, TransformedResume, TransformedExperience } from '../types'

interface LoginProps {
  username: string,
  password: string,
}

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
})

const login = ({ username, password }: LoginProps): Promise<ApiResponse<null>> => instance.post('/login', {}, { auth: { username, password } })
const getResume = (): Promise<ApiResponse<TransformedResume>> => instance.get('/resume').then((response: any) => {
  const data = transformResume(response.data)

  return { ...response, data }
})
const postResume = (resume: TransformedResume): Promise<ApiResponse<null>> => instance.post('/resume', reverseTransformResume(resume))

/**
 * This function exists because of a limitation in react-hook-form: collection of strings are not handled by `useFieldArray`
 * resume.content.experiences.*.tags: string[] is transformed to resume.content.experiences.*.tags: { value: string }[]
 */
function transformResume(resume: Resume): TransformedResume {
  const experiences = resume.content.experiences.map((experience: Experience): TransformedExperience => {
    const tags = experience.tags.map((tag: string): { value: string } => ({ value: tag }))
    const missions = experience.missions.map((mission: string): { value: string } => ({ value: mission }))

    return { ...experience, missions, tags }
  })

  return { ...resume, content: { ...resume.content, experiences } }
}

function reverseTransformResume(resume: TransformedResume): Resume {
  const experiences = resume.content.experiences.map((experience: TransformedExperience): Experience => {
    const tags = experience.tags.map((tag: { value: string }): string => (tag.value))
    const missions = experience.missions.map((mission: { value: string }): string => (mission.value))

    return { ...experience, missions, tags }
  })

  return { ...resume, content: { ...resume.content, experiences } }
}

const apiClient = {
  login,
  getResume,
  postResume,
}

export default apiClient
