import { useNavigate } from 'react-router-dom'
import { useAsyncCallback } from 'react-async-hook'

import Login from '../components/Login'
import useAuthentication from '../hooks/useAuthentication'
import apiClient from '../services/apiClient'

const LoginPage = (): JSX.Element => {
  const navigate = useNavigate()
  const [, setAuthenticated] = useAuthentication()
  const { loading, execute } = useAsyncCallback(apiClient.login)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget);
    const username = (data.get('login') || '') as string;
    const password = (data.get('password') || '') as string;
    await execute({ username, password })
    setAuthenticated(true)
    navigate('/', { replace: true })
  }

  return (
    <Login loading={loading} onSubmit={handleSubmit} onClose={() => {}} />
  )
}

export default LoginPage
