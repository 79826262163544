import { Button } from '@mui/material'
import { AddBoxOutlined as AddBoxOutlinedIcon } from '@mui/icons-material';

interface AddContactProps {
  onAdd: () => void,
}

const AddContact = ({ onAdd }: AddContactProps): JSX.Element => (
  <>
    <Button type="button" variant="text" onClick={onAdd}>
      <AddBoxOutlinedIcon /> Add Contact
    </Button>
  </>
)

export default AddContact
