import { useFormContext } from 'react-hook-form'
import { TextField } from '@mui/material'

const Theme = (): JSX.Element => {
  const { register } = useFormContext()

  return (
    <>
      <TextField {...register('theme.color.primary')} label="Primary Color" placeholder="#123456" />
    </>
  )
}

export default Theme
