import { createTheme, ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'

import Routes from './pages'

declare module '@mui/material/styles' {
  interface Theme {
    drawerWidth: number
  }
  interface ThemeOptions {
    drawerWidth?: number
  }
}

const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
    },
  },
  drawerWidth: 240,
})

const App = (): JSX.Element => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Routes />
  </ThemeProvider>
)

export default App
