import { useFormContext } from 'react-hook-form'
import { TextField } from '@mui/material'
import { Box } from '@mui/system'

const Meta = (): JSX.Element => {
  const { register } = useFormContext()

  return (
    <>
      <Box>
        <TextField {...register('meta.lang')} label="Lang" size="small" margin="dense" sx={{ marginRight: 1 }} />
        <TextField {...register('meta.locale')} label="Locale" size="small" margin="dense" />
      </Box>

      <Box>
        <TextField {...register('meta.title')} label="Title" fullWidth size="small" margin="dense" />
      </Box>

      <Box>
        <TextField {...register('meta.description')} label="description" fullWidth size="small" margin="dense" />
        <TextField {...register('meta.ogDescription')} label="og:description"fullWidth size="small" margin="dense" />
      </Box>

      <Box>
        <TextField {...register('meta.url')} label="URL" fullWidth size="small" margin="dense" />
      </Box>
    </>
  )
}

export default Meta
