import { Button } from '@mui/material'
import { AddBoxOutlined as AddBoxOutlinedIcon } from '@mui/icons-material';

interface AddTrainingProps {
  onAdd: () => void,
}

const AddTraining = ({ onAdd }: AddTrainingProps): JSX.Element => (
  <>
    <Button type="button" variant="text" onClick={onAdd}>
      <AddBoxOutlinedIcon /> Add Training
    </Button>
  </>
)

export default AddTraining
