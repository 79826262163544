import { useFieldArray, useFormContext } from 'react-hook-form'
import { Box, IconButton, TextField, Typography } from '@mui/material'
import { Delete as DeleteIcon, AddBoxOutlined as AddBoxOutlinedIcon } from '@mui/icons-material';

interface MissionsProps {
  source?: string
}

const Missions = ({ source }: MissionsProps): JSX.Element => {
  const { control, register } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${source ? source + '.' : ''}missions`,
  })

  return (
    <>
      {fields.length > 0 && <Typography variant="subtitle1">Missions</Typography>}

      {fields.map((field: any, index: number) => {
        const nameLabel = `${source ? source + '.' : ''}missions[${index}].value`

        return (
          <Box key={field.id}>
            <TextField {...register(nameLabel)} placeholder="Mission's description" size="small" margin="dense" />
            <IconButton onClick={() => remove(index)}><DeleteIcon /></IconButton>
          </Box>
        )
      })}

      <Box>
        <IconButton onClick={() => append({ value: '' })}>
          <AddBoxOutlinedIcon />
          <Typography variant="subtitle1">Add a mission</Typography>
        </IconButton>
      </Box>
    </>
  )
}

export default Missions
