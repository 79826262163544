import { Navigate } from 'react-router-dom'
import useAuthentication from '../hooks/useAuthentication'

interface AuthenticatedProps {
  children: JSX.Element
}

const Authenticated = ({ children }: AuthenticatedProps): JSX.Element => {
  const [isAuthenticated] = useAuthentication()

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />
  }

  return children
}

export default Authenticated
