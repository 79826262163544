import { useFieldArray, useFormContext } from 'react-hook-form'
import { Box, IconButton, TextField, Typography } from '@mui/material'
import { Delete as DeleteIcon, AddBoxOutlined as AddBoxOutlinedIcon } from '@mui/icons-material';

interface TagsProps {
  source?: string
}

const Tags = ({ source }: TagsProps): JSX.Element => {
  const { control, register } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${source ? source + '.' : ''}tags`,
  })

  return (
    <>
      {fields.length > 0 && <Typography variant="subtitle1">Tags</Typography>}

      {fields.map((field: any, index: number) => {
        const nameLabel = `${source ? source + '.' : ''}tags[${index}].value`

        return (
          <Box key={field.id}>
            <TextField {...register(nameLabel)} placeholder="Tag" size="small" margin="dense" sx={{ marginRight: 1 }} />
            <IconButton onClick={() => remove(index)}><DeleteIcon /></IconButton>
          </Box>
        )
      })}

      <Box>
        <IconButton onClick={() => append({ value: '' })}>
          <AddBoxOutlinedIcon />
          <Typography variant="subtitle1">Add a tag</Typography>
        </IconButton>
      </Box>
    </>
  )
}

export default Tags
