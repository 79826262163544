import { useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useAsync, useAsyncCallback } from 'react-async-hook'
import { Box, CircularProgress, Grid, Paper, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { grey } from '@mui/material/colors'
import PublishIcon from '@mui/icons-material/Publish'

import { Category, TransformedExperience, TransformedResume } from '../../types'
import apiClient from '../../services/apiClient'
import Contacts from '../../components/Resume/Contacts'
import Experiences from '../../components/Resume/Experiences'
import Meta from '../../components/Resume/Meta'
import Profile from '../../components/Resume/Profile'
import Skills from '../../components/Resume/Skills'
import Theme from '../../components/Resume/Theme'
import Trainings from '../../components/Resume/Trainings'
import AddExperience from '../../components/Resume/AddExperience'
import AddSkill from '../../components/Resume/AddSkill'
import AddTraining from '../../components/Resume/AddTraining'
import AddContact from '../../components/Resume/AddContact'

interface FormProps {
  category: Category
}

const Form = ({ category }: FormProps): JSX.Element => {
  const [resume, setResume] = useState<TransformedResume|null>(null)
  const formMethods = useForm({ defaultValues: {} })
  const { loading: resumeLoading, result: resultResume } = useAsync(apiClient.getResume, [])
  const { loading: postResumeLoading, execute: executePostResume } = useAsyncCallback(apiClient.postResume)

  useEffect(() => {
    if (!resultResume) {
      return
    }

    setResume(resultResume.data)
  }, [formMethods, resultResume])

  useEffect(() => {
    if (!resume) {
      return
    }

    formMethods.reset(resume)
  }, [formMethods, resume])

  const onAddExperience = (experience: TransformedExperience) => {
    if (!resume) {
      throw Error()
    }

    const experiences = resume.content.experiences
    const newExperiences = [ ...experiences, experience ]
    const newContent = { ...resume.content, experiences: newExperiences }
    setResume({ ...resume, content: newContent })
  }

  const onAddSkill = () => {
    if (!resume) {
      throw Error()
    }

    const skills = resume.content.skills
    const newSkills = [ ...skills, { label: '', value: 0 }]
    const newContent = { ...resume.content, skills: newSkills }
    setResume({ ...resume, content: newContent })
  }

  const onAddTraining = () => {
    if (!resume) {
      throw Error()
    }

    const trainings = resume.content.trainings
    const newTrainings = [ ...trainings, { type:'', label: '' }]
    const newContent = { ...resume.content, trainings: newTrainings }
    setResume({ ...resume, content: newContent })
  }

  const onAddContact = () => {
    if (!resume) {
      throw Error()
    }

    const contacts = resume.content.contacts
    const newContacts = [ ...contacts, { type: '', url:'' }]
    const newContent = { ...resume.content, contacts: newContacts }
    setResume({ ...resume, content: newContent })
  }

  const categoryMapping: { [K in Category]: { title: string; actions?: JSX.Element[]; content: JSX.Element } } = {
    theme: {
      title: 'Theme',
      content: <Theme />,
    },
    meta: {
      title: 'Meta',
      content: <Meta />,
    },
    profile: {
      title: 'Profile',
      content: <Profile />,
    },
    experiences: {
      title: 'Experiences',
      content: <Experiences />,
      actions: [<AddExperience key="addexperience" onAdd={onAddExperience} />],
    },
    skills: {
      title: 'Skills',
      content: <Skills />,
      actions: [<AddSkill key="addskill" onAdd={onAddSkill} />],

    },
    trainings: {
      title: 'Trainings',
      content: <Trainings />,
      actions: [<AddTraining key="addtraining" onAdd={onAddTraining} />],
    },
    contacts: {
      title: 'Contacts',
      content: <Contacts />,
      actions: [<AddContact key="addcontact" onAdd={onAddContact} />],
    },
  }

  const header = (
    <Grid container direction="row" justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h5" component="div">
          {categoryMapping[category].title}
        </Typography>
      </Grid>
      <Grid item>
        {categoryMapping[category].actions}
        <LoadingButton type="submit" variant="text" loading={postResumeLoading}>
          <PublishIcon /> Publish
        </LoadingButton>
      </Grid>
    </Grid>
  )

  const formLoading = (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: ({ drawerWidth }) => `calc(50% + ${drawerWidth}px)`,
        marginLeft: '-50px',
        marginTop: '-50px',
      }}
    >
      <CircularProgress size={100} />
    </Box>
  )

  const form = (
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(executePostResume)}>
          <Box sx={{ padding: 2, backgroundColor: grey[100] }}>{header}</Box>
          <Box sx={{ p: 4 }}>{categoryMapping[category].content}</Box>
        </form>
      </FormProvider>
  )

  return <Paper sx={{ width: '100%', typography: 'body1' }}>{resumeLoading ? formLoading : form}</Paper>
}

export default Form
