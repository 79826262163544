import { Box, Divider, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Toolbar } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import BrushIcon from '@mui/icons-material/Brush'
import PersonIcon from '@mui/icons-material/Person'
import SettingsIcon from '@mui/icons-material/Settings'
import WorkIcon from '@mui/icons-material/Work'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import SchoolIcon from '@mui/icons-material/School'
import LinkIcon from '@mui/icons-material/Link'
import { Category } from '../../types'

interface MenuProps {
  category: Category,
  setCategory: (category: Category) => void,
}

interface MenuItemProps {
  category: Category,
  title: string,
  icon: JSX.Element,
}

const Menu = ({ category, setCategory }: MenuProps) => {
  const MenuItem = ({ category: menuItemCategory, title, icon }: MenuItemProps) => (
    <ListItemButton onClick={() => setCategory(menuItemCategory)} selected={category === menuItemCategory}>
      <ListItemIcon>
        {icon}
      </ListItemIcon>
      <ListItemText primary={title} />
    </ListItemButton>
  )

  return (
    <Drawer anchor="left" variant="permanent">
      <Box sx={{ width: ({ drawerWidth }) => `${drawerWidth}px` }}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <IconButton onClick={() => {}}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          <MenuItem category="theme" title="Theme" icon={<BrushIcon />} />
          <MenuItem category="meta" title="Meta" icon={<SettingsIcon />} />
          <MenuItem category="profile" title="Profile" icon={<PersonIcon />} />
          <MenuItem category="experiences" title="Experiences" icon={<WorkIcon />} />
          <MenuItem category="skills" title="Skills" icon={<MenuBookIcon />} />
          <MenuItem category="trainings" title="Trainings" icon={<SchoolIcon />} />
          <MenuItem category="contacts" title="Contacts" icon={<LinkIcon />} />
        </List>
      </Box>
    </Drawer>
  )
}

export default Menu
