import { Routes as RouterRoutes, Route, HashRouter } from 'react-router-dom'

import Login from './Login'
import Resume from './EditResumePage'
import Authenticated from '../containers/Authenticated'

const Routes = () => (
  <HashRouter>
    <RouterRoutes>
      <Route path="/" element={<Authenticated><Resume /></Authenticated>} />
      <Route path="/login" element={<Login />} />
    </RouterRoutes>
  </HashRouter>
)

export default Routes
