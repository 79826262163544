import { useFieldArray, useFormContext } from 'react-hook-form'
import { Box, Chip, IconButton, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { Delete as DeleteIcon } from '@mui/icons-material';

const Experiences = (): JSX.Element => {
  const { control } = useFormContext()
  const { fields, remove } = useFieldArray({
    control,
    name: 'content.experiences',
  })

  return (
    <>
      {fields.map((field: any, index: number, array: any[]) => {
        return (
          <Box key={field.id}>
            <Typography variant="h6" component="div">{field.label}</Typography>
            <Typography variant="subtitle1" component="div">{field.jobTitle}</Typography>
            <Typography variant="body1" component="div">{field.description}</Typography>

            <Typography variant="subtitle2" component="div">Missions:</Typography>
            <ul>
              {field.missions.map((mission: any, jindex: number) => (
                <li key={`experiences[${index}].missions[${jindex}]`}><Typography variant="caption" component="div">{mission.value}</Typography></li>
              ))}
            </ul>

            <Typography variant="subtitle2" component="div">Tags:</Typography>
            <Box>
              {field.tags.map((tag: any, kindex: number) => (
                <Chip key={`experiences[${index}].tags[${kindex}]`}variant="outlined" label={tag.value} />
              ))}
            </Box>

            <IconButton onClick={() => remove(index)}>
              <DeleteIcon />
              <Typography variant="subtitle1">Remove experience</Typography>
            </IconButton>
            <hr color={grey[100]} style={{ margin: '32px auto' }} />
          </Box>
        )
      })}
    </>
  )
}

export default Experiences
