import { Box } from '@mui/material'
import { useState } from 'react'
import AppBar from '../components/AppBar'
import Menu from '../components/Resume/Menu'
import { Category } from '../types'
import EditResumeFormContainer from '../containers/Resume/Form'

const EditResumePage = (): JSX.Element => {
  const [category, setCategory] = useState<Category>('theme')

  return (
    <>
      <AppBar />
      <>
        <Menu category={category} setCategory={setCategory} />
        <Box sx={{ marginLeft: ({ drawerWidth }) => `${drawerWidth}px` }}>
          <EditResumeFormContainer category={category} />
        </Box>
      </>
    </>
  )
}

export default EditResumePage
