import { useFormContext } from 'react-hook-form'
import { FormGroup, TextField } from '@mui/material'

const Profile = (): JSX.Element => {
  const { register } = useFormContext()
  return (
    <>
      <FormGroup row={true}>
        <TextField {...register("profile.firstName")} label="Firstname" size="small" margin="dense" sx={{ marginRight: 1 }} />
        <TextField {...register("profile.lastName")} label="Lastname" size="small" margin="dense" />
      </FormGroup>

      <TextField {...register("profile.gender")} label="Gender" size="small" margin="dense" />

      <TextField {...register("profile.jobTitle")} label="Job Title" size="small" margin="dense" fullWidth />
    </>
  )
}

export default Profile
