import { useState, useEffect } from 'react'

const useAuthentication = (defaultValue: boolean = false): [boolean, (isAuthenticated: boolean) => void] => {
  const key = 'authenticated'
  const [value, setValue] = useState<boolean>(() => !!localStorage.getItem(key) || defaultValue)

  useEffect(() => localStorage.setItem(key, value ? '1' : '0'), [key, value])

  return [value, setValue]
}

export default useAuthentication
