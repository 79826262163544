import { Button } from '@mui/material'
import { AddBoxOutlined as AddBoxOutlinedIcon } from '@mui/icons-material';

interface AddSkillProps {
  onAdd: () => void,
}

const AddSkill = ({ onAdd }: AddSkillProps): JSX.Element => (
  <>
    <Button type="button" variant="text" onClick={onAdd}>
      <AddBoxOutlinedIcon /> Add Skill
    </Button>
  </>
)

export default AddSkill
