import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { AddBoxOutlined as AddBoxOutlinedIcon } from '@mui/icons-material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { TransformedExperience } from '../../types';
import Missions from './Missions';
import Tags from './Tags';

interface AddExperienceProps {
  onAdd: (experience: TransformedExperience) => void,
}

const AddExperience = ({ onAdd }: AddExperienceProps): JSX.Element => {
  const [open, setOpen] = useState(false)
  const { handleSubmit, register } = useForm({ defaultValues: { label: '', jobTitle: '', description: '', missions: [], tags: [] } })
  const onSubmit = (experience: TransformedExperience ) => {
    onAdd(experience)
    setOpen(false)
  }

  return (
    <>
      <Button type="button" variant="text" onClick={() => setOpen(true)}>
        <AddBoxOutlinedIcon /> Add Experience
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <form>
          <DialogTitle>Add Experience</DialogTitle>
          <DialogContent>
              <Box>
                <TextField {...register('label')} label="Label" size="small" margin="dense" sx={{ marginRight: 1 }} />
                <TextField {...register('jobTitle')} label="Job Title" size="small" margin="dense" />
              </Box>
              <Box>
                <TextField {...register('description')} label="Description" size="small" margin="dense" fullWidth multiline />
              </Box>

              <Missions />
              <Tags />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button type="submit" variant="text" onClick={handleSubmit(onSubmit)}>
              <AddBoxOutlinedIcon /> Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default AddExperience
