import { useFieldArray, useFormContext } from 'react-hook-form'
import { Box, IconButton, TextField } from '@mui/material'
import { Delete as DeleteIcon } from '@mui/icons-material';

const Contacts = (): JSX.Element => {
  const { control, register } = useFormContext()
  const { fields, remove } = useFieldArray({
    control,
    name: 'content.contacts'
  })

  return (
    <>
      {fields.map((field: any, index: number) => {
        const nameLabel = `content.contacts[${index}].type`
        const nameUrl = `content.contacts[${index}].url`

        return (
          <Box key={field.id}>
            <TextField {...register(nameLabel)} label="Type" size="small" margin="dense" sx={{ marginRight: 1 }}/>
            <TextField {...register(nameUrl)} label="URL" size="small" margin="dense" />
            <IconButton onClick={() => remove(index)}><DeleteIcon /></IconButton>
          </Box>
        )
      })}
    </>
  )
}

export default Contacts
